<template>
  <ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <div class="row">
      <div class="col-lg-12 col-sm-12">
        <ValidationProvider
          name="name"
          :rules="{
							required: true,
							min: 3,
							max: 50,
							doName: '^[A-Z][a-zA-Z]+$'
						}"
          v-slot="{ passed, failed, errors }"
        >
          <base-input
            v-model="collection.name"
            label="映射名"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
        </ValidationProvider>

        <div>
          <label>映射类型</label>
          <ValidationProvider
                  name="propType"
                  :rules="{
								required: true
							}"
                  v-slot="{ passed, failed, errors }"
          >
            <base-input
                    :error="errors[0]"
                    :class="[{ 'has-danger withoutAfter': failed }]">
              <el-select
                      v-model="collection.type"
                      filterable
                      placeholder="选择类型"
                      class="select-primary"
                      name="type"
              >
                <el-option
                        v-for="option in selects.types"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label"
                ></el-option>
              </el-select>
            </base-input>
          </ValidationProvider>
        </div>

        <base-input label="映射描述">
            <textarea
                    class="form-control"
                    placeholder="项目描述"
                    v-model="collection.description"
            >
            </textarea>
        </base-input>

        <div class="text-right">
          <base-button
                  native-type="submit"
                  type="primary"
          >创 建</base-button
          >
        </div>
      </div>
    </div>
  </form>
  </ValidationObserver>

</template>
<script>
import { BaseCheckbox } from 'src/components/index';
import { extend } from "vee-validate";
import { required, email, confirmed, regex, min, max } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';

extend("doName", {
  ...regex,
  message: '由首字母大写的字母组成！'
});
extend("min", {
  ...min,
  message: '长度不能少于{length}！'
});
extend("max", {
  ...max,
  message: '长度不能大于{length}！'
});

export default {
  components: {
    BaseCheckbox,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      collection:{
        name: "",
        type: "ENTITY",
        description: "",
        extraImports: [],
        doFields: [],
        offsetX:300,
        offsetY:300
      },
      selects:{
          types:[
              { value: 'ENTITY', label: 'ENTITY（实体）' },
              { value: 'EMBEDDED', label: 'EMBEDDED（嵌入式类）' }
          ]
      }
    };
  },
  methods: {
    submit() {
      this.$emit('addDo',this.collection);
    }
  },
  mounted() {
  }
};
</script>
<style></style>
