<template>
  <ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <div class="row">
      <div class="col-lg-12 col-sm-12">
        <ValidationProvider
          name="name"
          :rules="{
							required: true,
							min: 2,
							max: 50,
							dofName: '^[a-z][a-zA-Z]+$'
						}"
          v-slot="{ passed, failed, errors }"
        >
          <base-input
            v-model="dof.name"
            label="属性名"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
        </ValidationProvider>

        <div>
          <label>类型</label>
          <ValidationProvider
            name="propType"
            :rules="{
              required: true
            }"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
                    :error="errors[0]"
                    :class="[{ 'has-danger withoutAfter': failed }]">
              <el-select
                      v-model="dof.type"
                      filterable
                      placeholder="选择类型"
                      class="select-primary"
                      name="type"
              >
                <el-option
                        v-for="option in selects.types"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label"
                ></el-option>
              </el-select>
            </base-input>
          </ValidationProvider>
        </div>

        <div v-if="dof.type === 'OTHERS'">
          <ValidationProvider
                  name="typeStr"
                  rules="required"
                  v-slot="{ passed, failed, errors }"
          >
            <base-input
                    v-model="dof.typeStr"
                    label="属性类型（type string）"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
        </div>

        <div v-if="dof.type === 'RELATION'||dof.type === 'EMBEDDED_CLASS'">
          <label>相关映射</label>
          <ValidationProvider
                  name="propType"
                  :rules="{
              required: true
            }"
                  v-slot="{ passed, failed, errors }"
          >
            <base-input
                    :error="errors[0]"
                    :class="[{ 'has-danger withoutAfter': failed }]">
              <el-select
                      v-model="dof.refDoName"
                      filterable
                      placeholder="选择相关映射"
                      class="select-primary"
                      name="type"
              >
                <el-option
                        v-for="option in selects.refDoNames"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label"
                ></el-option>
              </el-select>
            </base-input>
          </ValidationProvider>
        </div>

        <div class="row">
          <div class="col-lg-3 col-sm-3" v-if="showIsIdSwitcher">
            <label>是否是主键</label>
            <base-switch
                    v-model="dof.dbId"
                    type="primary"
                    on-text="是"
                    off-text="否"
            ></base-switch>
          </div>
          <div class="col-lg-3 col-sm-3" v-if="showCollectionSwitcher">
            <label>是否是集</label>
            <base-switch
                    v-model="dof.collection"
                    type="primary"
                    on-text="是"
                    off-text="否"
            ></base-switch>
          </div>
          <div class="col-lg-3 col-sm-3" v-if="dof.type!=='RELATION'&&dof.type!=='EMBEDDED_CLASS'">
            <label>@Version</label>
            <base-switch
                    v-model="dof.version"
                    type="primary"
                    on-text="是"
                    off-text="否"
            ></base-switch>
          </div>
          <div class="col-lg-3 col-sm-3" v-if="dof.type!=='RELATION'&&dof.type!=='EMBEDDED_CLASS'">
            <label>不显示</label>
            <base-switch
                    v-model="dof.hide"
                    type="primary"
                    on-text="是"
                    off-text="否"
            ></base-switch>
          </div>
          <div class="col-lg-3 col-sm-3" v-if="dof.type!=='RELATION'&&dof.type!=='EMBEDDED_CLASS'">
            <label>@Indexed</label>
            <base-switch
                    v-model="dof.index"
                    type="primary"
                    on-text="是"
                    off-text="否"
            ></base-switch>
          </div>
          <div class="col-lg-6 col-sm-6" v-if="dof.collection">
            <label>集类型</label>
            <ValidationProvider
                    name="propType"
                    :rules="{
              required: true
            }"
                    v-slot="{ passed, failed, errors }"
            >
              <base-input
                      :error="errors[0]"
                      :class="[{ 'has-danger withoutAfter': failed }]">
                <el-select
                        v-model="dof.collectionType"
                        filterable
                        placeholder="选择集类型"
                        class="select-primary"
                        name="type"
                >
                  <el-option
                          v-for="option in selects.collectionTypes"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label"
                          :key="option.label"
                  ></el-option>
                </el-select>
              </base-input>
            </ValidationProvider>
          </div>
        </div>

        <div class="text-right">
          <base-button
                  native-type="submit"
                  type="primary"
          >创 建</base-button
          >
        </div>
      </div>
    </div>
  </form>
  </ValidationObserver>

</template>
<script>
import { BaseCheckbox } from 'src/components/index';
import { extend } from "vee-validate";
import { required, email, confirmed, regex, min, max } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';
import {
  BaseProgress,
  BaseSwitch,
  Slider,
  ImageUpload,
  TagsInput
} from 'src/components/index';

extend("dofName", {
  ...regex,
  message: '由首字母小写的字母组成！'
});
extend("min", {
  ...min,
  message: '长度不能少于{length}！'
});
extend("max", {
  ...max,
  message: '长度不能大于{length}！'
});

export default {
  props: {
    selectedDo: {
      type: Object,
      default: null
    }
  },
  components: {
    BaseCheckbox,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    BaseSwitch,
  },
  data() {
    return {
      dof:{
        name: "",
        type: "",
        typeStr: "",
        refDoName: "",
        dbId: false,
        collection:false,
        collectionType:"",
        version:false,
        index:false,
        hide:false,
      },
      selects:{
          types:[
              { value: 'byte', label: 'byte' },
              { value: 'short', label: 'short' },
              { value: 'Short', label: 'Short' },
              { value: 'int', label: 'int' },
              { value: 'Integer', label: 'Integer' },
              { value: 'long', label: 'long' },
              { value: 'Long', label: 'Long' },
              { value: 'float', label: 'float' },
              { value: 'Float', label: 'Float' },
              { value: 'double', label: 'double' },
              { value: 'Double', label: 'Double' },
              { value: 'char', label: 'char' },
              { value: 'Character', label: 'Character' },
              { value: 'boolean', label: 'boolean' },
              { value: 'Boolean', label: 'Boolean' },
              { value: 'String', label: 'String' },
              { value: 'BigInteger', label: 'BigInteger' },
              { value: 'BigDecimal', label: 'BigDecimal' },
              { value: 'Date', label: 'Date' },
              { value: 'Calendar', label: 'Calendar' },
              { value: 'Time', label: 'Time' },
              { value: 'TimeStamp', label: 'TimeStamp' },
              { value: 'RELATION', label: '关系（Relation）' },
              { value: 'EMBEDDED_CLASS', label: '嵌入式类（embedded class）' },
              { value: 'OTHERS', label: '其他（others）' }
          ],
          refDoNames:[
          ],
          collectionTypes:[
            { value: 'List', label: 'List' },
            { value: 'Set', label: 'Set' },
          ],
      }
    };
  },
  methods: {
    submit() {
      this.workoutTypeStr();
      let doIndex = this.selectedDo.doIndex;
      this.$eventHub.$emit('addDof', doIndex, this.dof);
    },
    workoutTypeStr(){
      switch(this.dof.type) {
        case 'RELATION':
        case 'EMBEDDED_CLASS':
          if(this.dof.collection){
            this.dof.typeStr = this.dof.collectionType + '<' + this.dof.refDoName + '>';
          }else{
            this.dof.typeStr = this.dof.refDoName;
          }
          break;
        case 'OTHERS':
          break;
        default:
          this.dof.typeStr = this.dof.type;
      }
    }
  },
  watch: {
    'dof.type': function (newVal, oldVal){
      this.dof.typeStr="";
      this.dof.refDoName="";
      this.dof.dbId=false;
      this.dof.collection=false;
      this.dof.collectionType="";
      this.dof.version=false;
      this.dof.index=false;
      this.dof.hide=false;
      if(newVal==='RELATION'){
        this.selects.refDoNames = this.selectedDo.entityOptions;
      }
      if(newVal==='EMBEDDED_CLASS'){
        this.selects.refDoNames = this.selectedDo.embeddedClassOptions;
      }
    }
  },
  computed: {
    showIsIdSwitcher() {
      return this.selectedDo.type==='ENTITY'
              &&(this.dof.type==='long'
                ||this.dof.type==='int'
                ||this.dof.type==='String'
                ||this.dof.type==='Date'
                ||this.dof.type==='Time'
                ||this.dof.type==='TimeStamp'
                ||this.dof.type==='EMBEDDED_CLASS'
                ||this.dof.type==='OTHERS');
    },
    showCollectionSwitcher() {
      return this.dof.type==='RELATION'
                ||this.dof.type==='EMBEDDED_CLASS';
    },
  },
  created() {

  }
};
</script>
<style></style>
