<template>
    <div>
        <base-nav
                v-model="showMenu"
                type="white"
                :transparent="true"
                menu-classes="justify-content-end"
                class="auth-navbar fixed-top"
        >
            <div slot="brand" class="navbar-wrapper">
                <a class="navbar-brand" href="#" v-if="title">{{ title }}</a>
            </div>

            <ul class="navbar-nav">
                <base-dropdown
                        menu-on-right=""
                        tag="div"
                        title-classes="btn btn-link btn-icon"
                        :class="{ 'float-left': $rtl.isRTL }"
                >
                    <i slot="title" class="tim-icons icon-settings-gear-63"></i>
                    <a class="dropdown-item" @click="openDoAddingForm" >新建映射</a>
                    <a class="dropdown-item text-primary" @click="editingProject" >保存</a>
                    <a class="dropdown-item" @click="closeProjectEditor">
                        关闭编辑器
                    </a>
                </base-dropdown>
            </ul>
        </base-nav>
        <div class="wrapper wrapper-full-page">
            <div class="full-page">
                <div class="content" style="height: 100vh;padding-bottom: 55px;padding-top: 55px;">
                    <ejs-diagram :key="diagramKey" id="diagram" width='100%' height='100%'
                                 :getNodeDefaults='getNodeDefaults' :nodeTemplate='nodeTemplate'
                                 :getConnectorDefaults='getConnectorDefaults'
                                 :constraints='constraints' :selectedItems='selectedItems'
                                 :positionChange='positionChange'
                                 style='background-color: #f1f6f8;'
                    >
                    </ejs-diagram>
                </div>
            </div>
        </div>

        <!-- Classic Modal -->
        <modal
                :class="{ largeModal:modal.largeModal, dark:modal.dark }"
                :show.sync="modal.open"
                v-if="modal.open"
                headerClasses="justify-content-center"
        >
            <h4 slot="header" class="title title-up">{{modal.title}}</h4>
            <component :is="modal.componentName"
                       @addDo="addDo"
            ></component>
        </modal>
    </div>
</template>
<script>
import { BaseNav } from 'src/components';
import { Modal } from 'src/components';

//fomrs
import DoAddingForm from './Forms/DoAddingForm.vue';
//project service
import projectService from 'src/services/project.service';
//diagram
import NodeTemplate from "./Diagram/TableDiagramTemplate.vue";
import { Diagram } from '@syncfusion/ej2-vue-diagrams';
import { DiagramConstraints,SelectorConstraints,NodeConstraints,ConnectorConstraints,ConnectorBridging } from '@syncfusion/ej2-vue-diagrams';
Diagram.Inject(ConnectorBridging);

export default {
  components: {
    Modal,
    BaseNav,
    DoAddingForm,
    NodeTemplate,
  },
  props: {
    selectedProject: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      showMenu: false,
      modal: {
        title:"表格",
        dark:true,
        componentName:"DoAddingForm",
        largeModal:false,
        open: false
      },
      project:{
        domainObjects:[],
      },
      nodeTemplate: function () {
        return { template: NodeTemplate };
      },
      diagramKey:0,
      getNodeDefaults: (node) => {
        node.shape.type = 'HTML';
        node.constraints = NodeConstraints.Default
                                & ~NodeConstraints.Delete;
        //style
        node.style = {
            fill: '#ffeec7',
            strokeColor: '#f5d897',
            strokeWidth: 1,
        };
        return node;
      },
      getConnectorDefaults: (obj) => {
        obj.type = 'Orthogonal';
        return obj;
      },
      constraints: DiagramConstraints.Default | DiagramConstraints.Bridging,
      selectedItems: {
        constraints: SelectorConstraints.None
      },
    };
  },
  computed: {
    title() {
      return `${this.$route.name}（${this.selectedProject?this.selectedProject.name:''}）`;
    },
  },
  methods: {
    workoutDiagramNodes(){
      let nodes = [];
      let pdos = JSON.parse(JSON.stringify(this.project.domainObjects));
      pdos.forEach((d,i) => {
          //设置node的宽度
          let width = 256;
          if(d.doFields){
            let largestLengthForNameString = d.doFields.reduce(function (largest, dof) {
                let totalLength = dof.name.length;
                return largest > totalLength ? largest : totalLength;
            }, 0);
            let largestLengthForTypeString = d.doFields.reduce(function (largest, dof) {
                let totalLength = dof.typeStr.length;
                return largest > totalLength ? largest : totalLength;
            }, 0);
            let largestLengthForNameAndTypeString = largestLengthForNameString + largestLengthForTypeString;
            if(largestLengthForNameAndTypeString > 14){
                width = 145 + largestLengthForNameAndTypeString*7.6;
            }
          }
          //设置node
          d.entityOptions = this.getEntityOptions();
          d.embeddedClassOptions = this.getEmbeddedClassOptions();
          d.doIndex = i;
          let node = {
            id:d.name,
            data:d,
            offsetX:d.offsetX?d.offsetX:300,
            offsetY:d.offsetY?d.offsetY:300,
            ports:[],
            height:d.doFields?66.5 + d.doFields.length*39:66.5,
            width:width
          };
          nodes.push(node);
          //add ports
          if(d.doFields){
              let totalHeight = 67 + 39*d.doFields.length-0.5;
              d.doFields.forEach((df,index) => {
                if(df.type==='RELATION'||df.type==='EMBEDDED_CLASS'){
                  node.ports.push({
                    id: df.name,
                    offset: {
                        x: 1,
                        //df_positionY/total_height
                        y: (57+(index*39)+19.75)/totalHeight,
                    }
                  });
                }
              });
          }
      })
      //删除node时 如果是最后一个 图像不处理 所以刷新整个图表
      return nodes;
    },
    workoutDiagramConnectors(){
      let connectors = [];
      let pdos = JSON.parse(JSON.stringify(this.project.domainObjects));
      //收集connector
      pdos.forEach(d => {
        if(d.doFields){
          d.doFields.forEach((df,index) => {
            if(df.type==='RELATION'
					||df.type==='EMBEDDED_CLASS'){
                let connector = {
                    constraints: ConnectorConstraints.Default &~ ConnectorConstraints.Select,
                    //source node id
                    sourceID: d.name,
                    sourcePortID: df.name,
                    //target node id
                    targetID: df.refDoName
                };
                connectors.push(connector);
		    }
          });
        }
      });
      return connectors;
    },
    openDoAddingForm(){
      this.modal.title = "新建映射";
      this.modal.open = true;
      this.modal.largeModal = false;
      this.modal.componentName = "DoAddingForm";
    },
    closeFormModal(){
      this.modal.title = "";
      this.modal.open = false;
      this.modal.componentName = "";
    },
	closeProjectEditor() {
		this.$router.push('projects');
    },
    addDo(d){
        this.closeFormModal();
        //如果已存在相同名字的domain object
        if(this.project.domainObjects.some(e=> e.name === d.name)){
            this.$pop.popError('映射名：'+ d.name +'已存在！');
        }else{
            this.project.domainObjects.push(d);
        }
    },
	refreshDiagram() {
        this.diagramKey += 1;
    },
    positionChange(args) {
		//完成拖动时触发
		if(args.state==='Completed'){
			let x = Math.round(args.source.offsetX);
			let y = Math.round(args.source.offsetY);
			//同时移动多个图像 或者只移动一个图像
			if(args.source.nodes){
                args.source.nodes.forEach(d => {
                    let x = Math.round(d.offsetX);
			        let y = Math.round(d.offsetY);
                    let doIndex = d.data.doIndex;
                    let movedDo = this.project.domainObjects[doIndex];
                    movedDo.offsetX = x;
                    movedDo.offsetY = y;
                });
			}else{
			    let x = Math.round(args.source.offsetX);
			    let y = Math.round(args.source.offsetY);
                let doIndex = args.source.data.doIndex;
                let movedDo = this.project.domainObjects[doIndex];
                movedDo.offsetX = x;
                movedDo.offsetY = y;
            }
		}
    },
    getEntityOptions(){
        let entityOptions = [];
        this.project.domainObjects.forEach(d => {
            if(d.type==='ENTITY'){
                let entityOption = { value: d.name, label: d.name };
                entityOptions.push(entityOption);
            }
        })
        return entityOptions;
    },
    getEmbeddedClassOptions(){
        let embeddedClassOptions = [];
        this.project.domainObjects.forEach(d => {
            if(d.type==='EMBEDDED'){
                let embeddedClassOption = { value: d.name, label: d.name };
                embeddedClassOptions.push(embeddedClassOption);
            }
        })
        return embeddedClassOptions;
    },
    editingProject(){
        this.$pop.showLoading();
        projectService.editCompanyProject(this.project,this.$router)
            .then(response => {
              this.project=response;
              this.$pop.popSucces("保存成功！");
            });
    },
  },
  watch: {
    'project.domainObjects': {
      handler(newValue, oldValue) {
        if(document.getElementById("diagram")){
          let diagram = document.getElementById("diagram").ej2_instances[0];
          diagram.nodes = this.workoutDiagramNodes();
          diagram.connectors = this.workoutDiagramConnectors();
          diagram.dataBind();
        }
      },
      deep: true
    }
  },
  created() {
    if(!this.selectedProject){
        this.$router.go(-1);
        return;
    }
    //retrieve project of company via projecturl(selectedProject ia a url)
    projectService.retrieveProjectOfCompany(this.selectedProject,this.$router)
      .then(response => {
            this.project = response;
          });

    //turn on events --listen on events in eventHub
    this.$eventHub.$on('editDo', (doIndex, d)=>{
        //如果已存在相同名字的domain object
        if(this.project.domainObjects.some((e,index)=> doIndex!==index&&e.name === d.name)){
            this.$pop.popError('映射名：'+ d.name +'已存在！');
        }else{
            this.project.domainObjects.splice(doIndex, 1, d);
        }
    });
    this.$eventHub.$on('removeDo', (doIndex)=>{
		this.project.domainObjects.splice(doIndex, 1);
		if(this.project.domainObjects.length === 0){
		    this.refreshDiagram();
		}
    });
    this.$eventHub.$on('addDof', (doIndex, dof)=>{
        //如果已存在相同名字的domain object
        if(this.project.domainObjects[doIndex].doFields.some(e=> e.name === dof.name)){
            this.$pop.popError('属性名：'+ dof.name +'已存在！');
        }else{
            this.project.domainObjects[doIndex].doFields.push(dof);
        }
    });
    this.$eventHub.$on('editDof', (doIndex, dofIndex, dof)=>{
        let domainObject = this.project.domainObjects[doIndex];
        //如果已存在相同名字的domain object
        if(domainObject.doFields.some((e,index)=> dofIndex!==index&&e.name === dof.name)){
            this.$pop.popError('属性名：'+ dof.name +'已存在！');
        }else{
            domainObject.doFields.splice(dofIndex, 1, dof);
        }
    });
    this.$eventHub.$on('removeDof', (doIndex, dofIndex)=>{
		this.project.domainObjects[doIndex].doFields.splice(dofIndex, 1);
    });
    this.$eventHub.$on('editDoExtraImports', (doIndex, d)=>{
		this.project.domainObjects.splice(doIndex, 1, d);
    });
  },
  beforeDestroy() {
	 //turn off do related events in eventHub
	 //do related event
     this.$eventHub.$off('editDo');
	 this.$eventHub.$off('removeDo');
	 this.$eventHub.$off('addDof');
	 this.$eventHub.$off('editDof');
	 this.$eventHub.$off('removeDof');
	 this.$eventHub.$off('editDoExtraImports');
  }
};
</script>
<style lang="scss">


.navbar.auth-navbar {
  top: 0;
}

$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}

.e-diagram-border {
	width: 0;
}

</style>
