<template>
  <ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <div class="row">
      <div class="col-lg-12 col-sm-12">
        <div class="row">
          <div  class="col-md-12"
                v-for="(item , index) in domainObject.extraImports"
                v-bind:key="index">
            <div class="row">
              <div class="col-md-10">
                <label style="color: white;">{{item}}</label>
              </div>
              <div class="col-md-2">
                <base-button type="link" class="btn btn-primary">
                  <i class="tim-icons icon-simple-remove" @click="removeImport(index)"></i>
                </base-button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8">
            <ValidationProvider
                    name="import"
                    :rules="{
							min: 2,
							max: 20
						}"
                    v-slot="{ passed, failed, errors }"
            >
              <base-input
                      v-model="addedImport"
                      :error="errors[0]"
                      :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
            </ValidationProvider>
          </div>
          <div class="col-md-4">
            <base-button link type="primary" class="float-right" @click.native="addImport">添加import</base-button>
          </div>
        </div>
        <br/>
        <div class="text-center">
          <base-button
                  native-type="submit"
                  type="primary"
          >保 存</base-button
          >
        </div>
      </div>
    </div>
  </form>
  </ValidationObserver>

</template>
<script>
import { extend } from "vee-validate";
import { min, max, required, regex } from "vee-validate/dist/rules";

import { TimeSelect, DatePicker, Select, Option } from 'element-ui';
import { BaseAlert } from 'src/components';
import { Table, TableColumn } from 'element-ui';
import {
  BaseProgress,
  BaseSwitch,
  Slider,
  ImageUpload,
  TagsInput
} from 'src/components/index';

extend("required", {
  ...required,
  message: '不能为空！'
});
extend("regexForpackageName", {
  ...regex,
  message: '由3-15个小写字母组成！'
});
extend("min", {
  ...min,
  message: '长度不能少于{length}！'
});
extend("max", {
  ...max,
  message: '长度不能大于{length}！'
});

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    BaseSwitch,
    BaseProgress,
    ImageUpload,
    TagsInput,
    Slider
  },
  props: {
    selectedDo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
        domainObject:JSON.parse(JSON.stringify(this.selectedDo)),
		addedImport:"",
    };
  },
  methods: {
    submit() {
		this.$eventHub.$emit('editDoExtraImports', this.selectedDo.doIndex, this.domainObject);
    },
	addImport() {
		if(this.addedImport){
			this.domainObject.extraImports.push(this.addedImport);
			this.addedImport = null;
		}
    },
	removeImport(index){
		this.domainObject.extraImports.splice(index,1);
	},
  },
  created() {
  }
};
</script>
<style></style>
