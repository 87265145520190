<template>
  <ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <div class="row">
      <div class="col-lg-12 col-sm-12">
        <ValidationProvider
                name="name"
                :rules="{
							required: true,
							min: 3,
							max: 50,
							doName: '^[A-Z][a-zA-Z]+$'
						}"
                v-slot="{ passed, failed, errors }"
        >
          <base-input
                  v-model="domainObject.name"
                  label="映射名"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
        </ValidationProvider>

        <base-input label="项目描述">
            <textarea
                    class="form-control"
                    placeholder="映射描述"
                    v-model="domainObject.description"
            >
            </textarea>
        </base-input>
        <div class="text-right">
          <base-button
                  v-if="isChanged"
                  native-type="submit"
                  type="primary"
          >编辑</base-button
          >
        </div>
      </div>
    </div>
  </form>
  </ValidationObserver>

</template>
<script>
import { BaseCheckbox } from 'src/components/index';
import { extend } from "vee-validate";
import { required, email, confirmed, regex, min, max } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("doName", {
  ...regex,
  message: '由首字母大写的字母组成！'
});
extend("min", {
  ...min,
  message: '长度不能少于{length}！'
});
extend("max", {
  ...max,
  message: '长度不能大于{length}！'
});

export default {
  components: {
    BaseCheckbox
  },
  props: {
    selectedDo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      domainObject:JSON.parse(JSON.stringify(this.selectedDo)),
      isChanged:false
    };
  },
  methods: {
    submit() {
      this.$eventHub.$emit("editDo", this.selectedDo.doIndex, this.domainObject);
    }
  },
  watch: {
    'domainObject': {
      handler(newValue, oldValue) {
        this.isChanged = true;
      },
      deep: true
    }
  },
  mounted() {
  }
};
</script>
<style></style>
