var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-sm-12"},[_c('div',{staticClass:"row"},_vm._l((_vm.domainObject.extraImports),function(item,index){return _c('div',{key:index,staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10"},[_c('label',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(item))])]),_c('div',{staticClass:"col-md-2"},[_c('base-button',{staticClass:"btn btn-primary",attrs:{"type":"link"}},[_c('i',{staticClass:"tim-icons icon-simple-remove",on:{"click":function($event){return _vm.removeImport(index)}}})])],1)])])}),0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('ValidationProvider',{attrs:{"name":"import","rules":{
							min: 2,
							max: 20
						}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var passed = ref.passed;
						var failed = ref.failed;
						var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"error":errors[0]},model:{value:(_vm.addedImport),callback:function ($$v) {_vm.addedImport=$$v},expression:"addedImport"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('base-button',{staticClass:"float-right",attrs:{"link":"","type":"primary"},nativeOn:{"click":function($event){return _vm.addImport.apply(null, arguments)}}},[_vm._v("添加import")])],1)]),_c('br'),_c('div',{staticClass:"text-center"},[_c('base-button',{attrs:{"native-type":"submit","type":"primary"}},[_vm._v("保 存")])],1)])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }