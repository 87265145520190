<template>
	<div>
		<Diagram-Card
		  style="background-color: #ffffff;"
          type="tasks"
          class="text-left"
          :header-classes="{ 'text-right': $rtl.isRTL }"
		  :header-style="{ 'background-color': headerColor }"
        >
          <template slot="header" style="color:black">
			<el-tooltip
			  :content="data.data.description"
			  effect="light"
			  :open-delay="300"
			  placement="top"
			  v-if="data.data.description"
			>
				<p class="title d-inline" style="color: black;margin-right: 5px;">{{data.data.name}}</p>
			</el-tooltip>
			<p v-else class="title d-inline" style="color: black;margin-right: 5px;">{{data.data.name}}</p>
            <base-dropdown
              menu-on-right=""
              tag="div"
              title-classes="btn btn-link btn-icon"
              :class="{ 'float-left': $rtl.isRTL }"
            >
              <i slot="title" class="tim-icons icon-settings-gear-63"></i>
			  <a class="dropdown-item"  @click="openDofAddingForm(data.data)">添加属性</a>
              <a class="dropdown-item"  @click="openDoEditingForm(data.data)">编辑</a>
			  <a class="dropdown-item"  @click="openExtraImportsEditingForm(data.data)">编辑额外imports</a>
			  <a class="dropdown-item text-primary" @click="deleteTable">删除</a>
            </base-dropdown>
          </template>
          <template class="table-full-width table-responsive">
			  <base-table :data="data.data.doFields" thead-classes="text-primary">
				<template slot-scope="{ row,index }">
				  <td style="width: 10px;padding:0">
					<i class="tim-icons icon-key-25" v-if="row.dbId"></i>
					<i v-if="row.isTransient">T</i>
					<i v-if="row.type==='VERSION'">V</i>
				  </td>
				  <td style="width: 10px;">
					<p class="title text-left" style="color:rgb(186 191 195);">{{ row.typeStr }}</p>
				  </td>
				  <td>
					<p class="title text-left" style="color:rgb(186 191 195);" >{{ row.name }}</p>
				  </td>
				  <td style="width: 10px;">
					<base-dropdown
					  menu-on-right=""
					  tag="div"
					  title-classes="btn btn-link btn-icon"
					  :class="{ 'float-left': $rtl.isRTL }"
					>
					  <i slot="title" class="tim-icons icon-settings-gear-63"></i>
					  <a class="dropdown-item" @click="openDofEditingForm(data.data,row,index)">编辑</a>
					  <a class="dropdown-item text-primary" @click="deleteDOProp(data.data,index)">删除</a>
					</base-dropdown>
				  </td>
				</template>
			  </base-table>
          </template>
        </Diagram-Card>

		<!-- Classic Modal -->
		<modal
				:class="{ largeModal:modal.largeModal, dark:modal.dark }"
				:show.sync="modal.open"
				v-if="modal.open"
				headerClasses="justify-content-center"
		>
			<h4 slot="header" class="title title-up">{{modal.title}}</h4>
			<component :is="modal.componentName"
					   :selectedDo="selectedDo" :selectedDof="selectedDof" :selectedDofIndex="selectedDofIndex"
			></component>
		</modal>
	</div>
</template>

<script>
import { BaseTable } from '@/components';
import DiagramCard from "./DiagramCard.vue";

import { Modal } from 'src/components';

//fomrs
import DoEditingForm from '../Forms/DoEditingForm.vue';
import DofAddingForm from '../Forms/DofAddingForm.vue';
import DofEditingForm from '../Forms/DofEditingForm.vue';
import ExtraImportsEditingForm from '../Forms/ExtraImportsEditingForm.vue';

export default {
  components: {
    BaseTable,
	DiagramCard,
	Modal,
	DoEditingForm,
	DofAddingForm,
	DofEditingForm,
	ExtraImportsEditingForm,
  },
  props: {
    test: {
      type: Object,
      default: null
    }
  },
  data() {
	return {
	  data: {},
      modal: {
        title:"表格",
        dark:true,
        componentName:"CollectionAddingForm",
        largeModal:false,
        open: false
      },
	  deleteTableRequestBody:{},
	  selectedDo:null,
	  selectedDof:null,
	  selectedDofIndex:null,
	}
  },
  methods: {
    openDoEditingForm(domainObject){
      this.modal.title = "编辑映射";
      this.modal.open = true;
      this.modal.largeModal = false;
      this.modal.componentName = "DoEditingForm";
      this.selectedDo = domainObject;
    },
    openDofAddingForm(domainObject){
      this.modal.title = "添加映射属性";
      this.modal.open = true;
      this.modal.largeModal = false;
      this.modal.componentName = "DofAddingForm";
      this.selectedDo = domainObject;
    },
    openDofEditingForm(domainObject , dof, dofIndex){
      this.modal.title = "编辑映射属性";
      this.modal.open = true;
      this.modal.largeModal = false;
      this.modal.componentName = "DofEditingForm";
      this.selectedDo = domainObject;
      this.selectedDof = dof;
      this.selectedDofIndex = dofIndex;
    },
    openExtraImportsEditingForm(domainObject){
      this.modal.title = "编辑extra imports";
      this.modal.open = true;
      this.modal.largeModal = false;
      this.modal.componentName = "ExtraImportsEditingForm";
      this.selectedDo = domainObject;
    },
    closeFormModal(){
      this.modal.title = "";
      this.modal.open = false;
      this.modal.componentName = "";
    },
    deleteTable() {
		this.$eventHub.$emit("removeDo", this.data.data.doIndex);
    },
	deleteDOProp(domainObject,dofIndex) {
		let doIndex = domainObject.doIndex;
		this.$eventHub.$emit("removeDof", doIndex, dofIndex);
    },
  },
  computed: {
	headerColor: function () {
		switch (this.data.data.type) {
			case 'ENTITY':
				return "#ebf4ff";
			case 'EMBEDDED':
				return "rgb(118 253 147)";
			case 'EMBEDDED_ID':
				return "rgb(245 253 118)";
			default:
				return "#ebf4ff";
		}
	}
  },
  mounted() {
  }
}
</script>
<style>
i:hover {
  color: #9A9A9A !important;
}

td .dropdown button{
	margin: 0;
	top: 3px;
	left: 15px;
}
</style>